<template>
  <div class="absolute shift-points-table p-4">
    <div class="overflow-auto h-full border-gray-500 border">
      <table>
        <tr class="text-center font-bold">
          <td>Index</td>
          <td>Data</td>
          <td>Latitude</td>
          <td>Longitude</td>
        </tr>
        <tr
            v-for="(point, index) in shiftPoints"
            :key="index"
            class="cursor-pointer"
            @mouseover="$emit('hover-point', index);"
            @mouseout="$emit('hover-point', null);"
        >
          <td><b>{{ index + 1 }}</b></td>
          <td>{{ point.datetime }}</td>
          <td>{{ point.latitude }}</td>
          <td>{{ point.longitude }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShiftPointsTable",
  props: {
    shiftPoints: {
      type: Array,
      required: true,
    }
  }
}
</script>

<style scoped>
.shift-points-table {
  background-color: white;
  border: 2px solid #DF8D36;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border-right-width: 0!important;
  height: 50vh;
  right: 0;
  top: 25%;
}

table {
  text-align: center;
  border-collapse: collapse;
}

table tr {
  height: 30px;
}

table tr:hover {
  background-color: rgba(128, 128, 128, 0.25);
}

table, tr, td {
  border: 1px solid black;
  padding: 3px;
}
</style>