<template>
<div class="main-map relative">
  <div class="absolute search-input">
    <gmap-autocomplete
        placeholder="Szukaj..."
        :options="{fields: ['geometry', 'address_components'], strictBounds: true}"
        :select-first-on-enter="true"
        @place_changed="setPlace">
    </gmap-autocomplete>
  </div>
  <GmapMap
      :center="center"
      :zoom="zoom"
      :options="mapOptions"
      map-type-id="terrain"
      @zoom_changed="handleChangeZoom"
      @bounds_changed="handleChangeBounds"
      class="map-class"
      style="width: 100%; height: 100%"
      ref="gmap"
  >
    <template v-if="mapLoaded">
      <Gmap-Marker
          v-if="this.place"
          :position="{
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        }"
      />
      <gmap-cluster :max-zoom="groupMarkers ? 19 : 0">
        <GmapMarker
            v-for="(postman) in showActivePostmen ? activePostmen : []"
            :key="postman.postman_id"
            :position="{lat: Number(postman.latitude), lng: Number(postman.longitude)}"
            :icon="{
            url: 'http://maps.google.com/mapfiles/ms/icons/police.png',
            size: {width: 43, height: 43, f: 'px', b: 'px'},
            scaledSize: {width: 43, height: 43, f: 'px', b: 'px'}
          }"
            @mouseover="handleHoverPostman(postman)"
            @mouseout="handleHoverPostman(null)"
            @click="handleClickActivePostman(postman)"
        />
      </gmap-cluster>
      <template v-if="!showPointsTable">
        <gmap-cluster :max-zoom="groupMarkers && !playPauseStatus  ? 19 : 0">
          <GmapMarker
              v-for="(point, index) in localShiftPostman"
              :key="index"
              :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
              :clickable="true"
              @mouseover="handleHoverShiftPoint(point, index)"
              @mouseout="handleHoverShiftPoint(null)"
              @click="handleClickShiftPoint(point, index)"
              :icon="point.type === 'scan' ?  ({url: 'http://maps.google.com/mapfiles/kml/shapes/post_office.png'}): ''"
          />
        </gmap-cluster>
        <GmapPolyline
            v-if="(localShiftPostman && localShiftPostman.length && showShiftLines) || playPauseStatus"
            :path="polyLinePath"
            :options="polyLineOptions"
        />
      </template>
      <template v-if="showPointsTable">
        <GmapMarker
            v-for="(point, index) in ShiftPostmanFromTable"
            :key="index"
            :position="{lat: Number(point.latitude), lng: Number(point.longitude)}"
            :icon="point.type === 'scan' ?  ({url: 'http://maps.google.com/mapfiles/kml/shapes/post_office.png'}): ''"
        />
        <GmapPolyline
            :path="polyLinePathFromTable"
            :options="polyLineOptions"
        />
      </template>
    </template>
  </GmapMap>
  <ShiftPointsTable
      v-if="showPointsTable"
      :shift-points="shiftPostmanAll"
      @hover-point="handleHoveredPointFromTable"
  />
  <div
      v-if="hoveredPoly || hoveredPostman || hoveredShiftPoint || playPauseStatus"
      :class="[
          'hovered-poly',
          'rounded-r-xl',
          {'border-blue-500' : hoveredPostman && !hoveredShiftPoint, 'border-red-500': hoveredShiftPoint || playPauseStatus},
          'absolute',
          'p-2',
          {'pt-0': !hoveredShiftPoint && !hoveredPostman && hoveredPoly && hoveredPoly.layer && !playPauseStatus}
        ]"
      :style="{borderColor: (!hoveredPostman && !hoveredShiftPoint && !playPauseStatus) ? (showCoverage ? getColorForPercentage(hoveredPoly.coverage) : hoveredPoly.color) : ''}"
  >
    <div class="text-2xl">
      <template v-if="playPauseStatus">
        <b class="rounded text-red-700">{{ playLinesStep + 1 }}/{{ shiftPostmanAll.length }}</b>
        Listonosz id: <b>{{this.shiftPostmanAll[playLinesStep].postman_id }}</b>
        Data: <b>{{ this.shiftPostmanAll[playLinesStep].datetime }}</b>
      </template>
      <template v-else-if="hoveredShiftPoint">
        <b class="rounded text-red-700">{{ hoveredShiftPoint.index }}/{{ shiftPostmanAll.length }}</b>
        Listonosz id: <b>{{ hoveredShiftPoint.postman_id }}</b>
        Data: <b>{{ hoveredShiftPoint.datetime }}</b>
      </template>
      <template v-else-if="hoveredPostman">
        Listonosz id: <b>{{ hoveredPostman.postman_id }}</b>
        Data: <b>{{ hoveredPostman.datetime }}</b>
      </template>
      <template v-else>
        <template v-if="hoveredPoly.layer">
          <span class="text-sm pt-0 pb-0" >{{ hoveredPoly.layer }} | </span>
          <span class="text-sm pt-0 pb-0" >Pokrycie: {{ hoveredPoly.coverage }}%</span>
          <br>
        </template>
        {{ hoveredPoly.name }}
        <p v-if="hoveredPoly.parent" class="text-sm pt-0 pb-0" >Rodzic: {{ hoveredPoly.parent }}</p>
      </template>
    </div>
  </div>
  <div v-if="loading" class="loader-wrapper absolute">
    <div class="loader-inner rounded-t-lg flex flex-row items-center p-2 justify-center">
      <div class="text-white font-bold text-xl mr-2">Ładowanie</div>
      <div class="loader"></div>
    </div>
  </div>
</div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import {geoJsonFromWKT, getColorForPercentage} from "@/utils/helpers";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import ShiftPointsTable from "@/components/ShiftPointsTable";

export default {
  name: "MapComponent",
  components: {ShiftPointsTable, 'gmap-cluster': GmapCluster},
  data: () => ({
    mapLoaded: false,
    boundsFitted: false,
    center: {lat: 52.237049, lng: 19.7},
    zoom: 7,
    mapOptions: {
      maxZoom: 20,
      streetViewControl: false,
    },
    place: null,
    hoveredPoly: null,
    hoveredPostman: null,
    hoveredShiftPoint: null,
    shiftPointsTimer: null,
    hoveredPointFromTable: null,
  }),
  props: {
    polygons: {
      type: Array,
      default: () => ([])
    },
    activePostmen: {
      type: Array,
      default: () => ([])
    },
    showActivePostmen: {
      type: Boolean,
      default: false,
    },
    shiftPostman: {
      type: Array,
      default: () => ([])
    },
    shiftPostmanScans: {
      type: Array,
      default: () => ([])
    },
    shiftPostmanAll: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    groupMarkers: {
      type: Boolean,
      default: true
    },
    showShiftLines: {
      type: Boolean,
      default: true
    },
    playLinesStatus: {
      type: String,
      default: 'stop'
    },
    playLinesStep: {
      type: Number,
      default: 0,
    },
    showCoverage: {
      type: Boolean,
      default: false,
    },
    showPointsTable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    google() {
      return gmapApi()
    },
    polyLinePath() {
      const path = this.localShiftPostman.map((point) => {
        return {lat: Number(point.latitude), lng: Number(point.longitude)}
      });

      return path;
    },
    polyLinePathFromTable() {
      const path = this.ShiftPostmanFromTable.map((point) => {
        return {lat: Number(point.latitude), lng: Number(point.longitude)}
      });

      return path;
    },
    playPauseStatus() {
      return !!(this.playLinesStatus === 'play' || this.playLinesStatus === 'pause');
    },
    polyLineOptions() {
      return {
        strokeColor: 'red',
        strokeWidth: 1,
        icons: [{
          icon: {
            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 2,
            fillColor: '#22bf15'
          },
          offset: '100%',
          repeat: '100px'
        }]
      }
    },
    localShiftPostman() {
      if (this.playLinesStatus === 'stop') {
        return this.shiftPostmanAll;
      } else {
        return this.shiftPostmanAll.slice(0, this.playLinesStep + 1);
      }
    },
    localShiftPostmanScan() {
      if (this.playLinesStatus === 'stop') {
        return this.shiftPostmanScans;
      } else {
        return this.shiftPostmanScans.slice(0, this.playLinesStep + 1);
      }
    },
    ShiftPostmanFromTable() {
      return this.shiftPostmanAll.slice(0, this.hoveredPointFromTable + 1);
    },
    polygonsOrCoverage() {
      return {polygons: this.polygons, showCoverage: this.showCoverage}
    }
  },
  watch: {
    polygonsOrCoverage(newValue, oldValue) {
      const coverageChanged = (newValue.showCoverage !== oldValue.showCoverage);
      const geoJson = geoJsonFromWKT(this.polygons);
      this.$refs.gmap.$mapPromise.then((map) => {
        const newPolsIds = geoJson.features.map(feature => feature.properties.id)

        map.data.forEach((feature) => {
          if (coverageChanged) {
            map.data.remove(feature);
          } else {
            if (!newPolsIds.includes(feature.getProperty('id'))) {
              map.data.remove(feature);
            } else {
              const index = geoJson.features.findIndex(newFeature => newFeature.properties.id === feature.getProperty('id'));
              if (index !== -1) {
                geoJson.features.splice(index, 1);
              }
            }
          }
        });

        map.data.addGeoJson(geoJson);

        map.data.setStyle((feature) => {
          const fillColorValue = this.showCoverage ? getColorForPercentage(feature.getProperty("coverage")) : feature.getProperty("color");
          return {
            fillColor: fillColorValue,
            strokeWeight: 0.5,
          };
        });
      });
    },
    playLinesStatus(val) {
      if (val === 'stop') {
        clearInterval(this.shiftPointsTimer);
      } else if (val === 'pause') {
        clearInterval(this.shiftPointsTimer);
      } else if (val === 'play') {
        this.shiftPointsTimer = setInterval(() => {
          this.$emit('change-play-lines-step', this.playLinesStep + 1);
        }, 1000);
      }
    },
    localShiftPostman(val) {
      this.$refs.gmap.$mapPromise.then(() => {
        if (this.boundsFitted) return;
        if (!val || !this.polyLinePath.length) return;

        const bounds = new this.google.maps.LatLngBounds();
        for (let m of this.polyLinePath) {
          bounds.extend(m);
        }

        this.$refs.gmap.fitBounds(bounds);
        this.boundsFitted = true;
      })
    }
  },
  async mounted() {
    const map = await this.$refs.gmap.$mapPromise
    this.mapLoaded = true;
    // map.data.addListener('click', (value) => {
    //   this.handleClickPoly(value)
    // })
    map.data.addListener('mouseover', (value) => {
      this.handleHoverPoly(value.feature.j ? value.feature.j : value.feature.i);
    })
    map.data.addListener('mouseout', () => {
      this.handleHoverPoly(null);
    })
  },
  methods: {
    setPlace(place) {
      this.place = place
      if(this.place) {
        this.center = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng()
        }
        this.zoom = 10;
      }
    },
    handleChangeZoom(value) {
      this.$emit('change-zoom', value)
    },
    handleChangeBounds(value) {
      this.$emit('change-bounds', value)
    },
    // handleClickPoly(value) {
    //   this.$emit('select-poly', value)
    // },
    handleClickShiftPoint(point, index, type = 'route') {
      this.$emit('select-shift-point', { ...point, index: index + 1, type });
    },
    handleClickActivePostman(postman) {
      this.$emit('select-active-postman', postman);
    },
    handleHoverPoly(poly) {
      this.hoveredPoly = poly;
    },
    handleHoverPostman(postman) {
      this.hoveredPostman = postman;
    },
    handleHoverShiftPoint(point, index) {
      if (point === null) {
        this.hoveredShiftPoint = null;
      }
      else {
        this.hoveredShiftPoint = { ...point, index: index + 1 };
      }
    },
    handleHoveredPointFromTable(pointIndex) {
      if (pointIndex === null) {
        this.hoveredPointFromTable = null;
      } else {
        this.hoveredPointFromTable = pointIndex;
      }

    },
    getColorForPercentage,
  },
}
</script>

<style scoped>
.main-map {
  width: 100%;
  height: 100%;
}

.loader-wrapper {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader-inner {
  width: 160px;
  background-color: #DF8D36;
}

.search-input {
  top: 0;
  left: 0;
  z-index: 2;
}

.search-input input {
  width: 300px;
  padding: 7px 14px;
  border-bottom-right-radius: 7px;
  outline: none!important;
  border: 1px solid black;
  border-top-width: 0;
  border-left-width: 0;
  -moz-box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
  -webkit-box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
  box-shadow: 2px 2px 5px rgba(68,68,68,0.4);
}

.hovered-poly {
  background-color: whitesmoke;
  border-width: 5px;
  border-left-width: 0;
  left: 0;
  bottom: 60px;
  -moz-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #DF8D36;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
.gmnoprint:first-child {
  margin: 50px 10px 10px 10px!important;
}
</style>