<template>
  <div class="map-page">
    <div class="content">
      <MapComponent
          :polygons="polygons"
          :active-postmen="activePostmen"
          :shift-postman="shiftPostman"
          :shift-postman-scans="shiftPostmanScans"
          :shift-postman-all="shiftPostmanAll"
          :show-active-postmen="showActivePostmen"
          :group-markers="groupMarkers"
          :show-shift-lines="showShiftLines"
          :show-coverage="showCoverage"
          :play-lines-status="playLinesStatus"
          :play-lines-step="playLinesStep"
          :loading="loading"
          :show-points-table="showShiftPointsTable"
          @change-bounds="handleChangeBounds"
          @change-zoom="handleResize"
          @select-shift-point="handleSelectShiftPoint"
          @select-active-postman="handleSelectActivePostman"
          @change-play-lines-step="changePlayLinesStep"
          ref="map"
      />
<!--      <PolygonInfoModal-->
<!--          :poly="selectedPoly"-->
<!--          :show-modal="showInfoModal"-->
<!--          @close-modal="handleCloseInfoModal"-->
<!--      />-->
      <AppSettingsModal
          :show-modal="showSettingsModal"
          @close-modal="handleCloseSettingsModal"
      />
      <ShiftPointInfoModal
          :point="selectedShiftPoint"
          :show-modal="showShiftPointInfoModal"
          :number-of-points="shiftPostmanAll.length"
          @close-modal="handleCloseShiftPointInfoModal"
      />
      <ActivePostmanInfoModal
          :postman="selectedActivePostman"
          :show-modal="showActivePostmanInfoModal"
          @close-modal="handleCloseActivePostmanInfoModal"
      />
      <ListOfActivePostmenModal
          :postmen="activePostmen"
          :show-modal="showActivePostmenListModal"
          @close-modal="handleCloseActivePostmenListModal"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { createNamespacedHelpers } from 'vuex';
import {
  CHANGE_PLAY_LINES_STEP,
  FETCH_ACTIVE_POSTMEN,
  FETCH_POLYGONS,
  FETCH_SHIFT_POSTMAN,
  INIT_SETTINGS
} from "@/store/modules/map/actions";
import MapComponent from "@/components/MapComponent";
import AppSettingsModal from "@/components/AppSettingsModal";
import {
  SET_AUTO_REFRESH_TIME,
  SET_MAP_COORDINATES,
  SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
  SET_SHOW_SETTINGS_MODAL,
  SET_ZOOM
} from "@/store/modules/map/mutations";
import ShiftPointInfoModal from "@/components/ShiftPointInfoModal";
import ActivePostmanInfoModal from "@/components/ActivePostmanInfoModal";
import ListOfActivePostmenModal from "@/components/ListOfActivePostmenModal";

const { mapActions, mapState, mapMutations } = createNamespacedHelpers('map');

export default {
  name: "Map",
  components: {ListOfActivePostmenModal, ShiftPointInfoModal, AppSettingsModal, MapComponent, ActivePostmanInfoModal},
  data: () => ({
    // selectedPoly: {},
    // showInfoModal: false,
    queryParams: {},
    selectedShiftPoint: {},
    selectedActivePostman: {},
    showShiftPointInfoModal: false,
    showActivePostmanInfoModal: false,
  }),
  computed: {
    ...mapState({
      showSettingsModal: state => state.showSettingsModal,
      showActivePostmenListModal: state => state.showActivePostmenListModal,
      polygons: state => state.polygons,
      activePostmen: state => state.activePostmen,
      showActivePostmen: state => state.showActivePostmen,
      shiftPostman: state => state.shiftPostman,
      shiftPostmanScans: state => state.shiftPostmanScans,
      shiftPostmanAll: state => state.shiftPostmanAll,
      loading: state => state.loading,
      groupMarkers: state => state.groupMarkers,
      showShiftLines: state => state.showShiftLines,
      showCoverage: state => state.showCoverage,
      selectedLayer: state => state.selectedLayer,
      autoRefresh: state => state.autoRefresh,
      autoRefreshTime: state => state.autoRefreshTime,
      dateTimeForActivePostmen: state => state.dateTimeForActivePostmen,
      playLinesStatus: state => state.playLinesStatus,
      playLinesStep: state => state.playLinesStep,
      showShiftPointsTable: state => state.showShiftPointsTable,
    })
  },
  mounted() {
    this.initSettings();

    const { key, date, shiftId, refresh, newFormat } = this.$route.query;
    this.queryParams = {key, date, shiftId, refresh, newFormat};
    if (key && date) {
      this.fetchShiftPostman({ key, date, shiftId, newFormat });
    } else {
      this.fetchActivePostmen();
    }

    this.setAutoRefreshTime(60);
    setInterval(()=> {
      this.setAutoRefreshTime(this.autoRefreshTime - 1);
    }, 1000);
  },
  watch: {
    autoRefreshTime(time) {
      const { key, date, shiftId, refresh, newFormat } = this.queryParams;
      if (time > 0 || (!refresh && !this.autoRefresh) || (refresh && !JSON.parse(refresh))) return;
      if (key && date) {
        this.fetchShiftPostman({ key, date, shiftId, newFormat });
      } else {
        if (this.showActivePostmen && !this.dateTimeForActivePostmen.start) {
          this.fetchActivePostmen();
        }
      }
      this.setAutoRefreshTime(60);
    },
  },
  methods: {
    ...mapActions({
      fetchPolygons: FETCH_POLYGONS,
      fetchActivePostmen: FETCH_ACTIVE_POSTMEN,
      fetchShiftPostman: FETCH_SHIFT_POSTMAN,
      initSettings: INIT_SETTINGS,
      changePlayLinesStep: CHANGE_PLAY_LINES_STEP
    }),
    ...mapMutations({
      setCoordinates: SET_MAP_COORDINATES,
      setZoom: SET_ZOOM,
      setShowSettingsModal: SET_SHOW_SETTINGS_MODAL,
      setShowActivePostmenListModal: SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
      setAutoRefreshTime: SET_AUTO_REFRESH_TIME,
    }),
    handleResize(zoom) { // zoom from 0 to 21 (max for our app - 20)
      this.setZoom(zoom);
    },
    handleChangeBounds: debounce (async function (value) {
      if (value) {
        const southWest = value.getSouthWest();
        const northEast = value.getNorthEast();
        this.setCoordinates({southWest, northEast})
        if (this.selectedLayer !== 'none') {
          this.fetchPolygons();
        }
      }
    }, 400),
    // handleSelectPoly(poly) {
    //   this.selectedPoly = poly.feature.j;
    //   this.showInfoModal = true;
    // },
    handleSelectShiftPoint(point) {
      this.selectedShiftPoint = point;
      this.showShiftPointInfoModal = true;
    },
    handleSelectActivePostman(postman) {
      this.selectedActivePostman = postman;
      this.showActivePostmanInfoModal = true;
    },
    // handleCloseInfoModal() {
    //   this.showInfoModal = false;
    // },
    handleCloseShiftPointInfoModal() {
      this.showShiftPointInfoModal = false;
    },
    handleCloseActivePostmanInfoModal() {
      this.showActivePostmanInfoModal = false;
    },
    handleCloseSettingsModal() {
      this.setShowSettingsModal(false);
    },
    handleCloseActivePostmenListModal() {
      this.setShowActivePostmenListModal(false);
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
}
</style>

<style>
.close-btn {
  border-color: #f83a3a;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-image: linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.close-btn:hover {
  background-position: 0;
}

.vm--modal {
  border-radius: 20px!important;
  border: 3px solid #DF8D36;
}

*:focus {
  outline: none !important
}
</style>